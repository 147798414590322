.widget.adblock {
  display: flex;
  position: fixed;
  z-index: 2;
  width: 100%;
  height: 100%;
  background-color: rgb(22, 19, 38, 0.9);
  backdrop-filter: blur(20px);
}
.widget.adblock .babies {
  display: block;
  width: 457px;
  height: 232px;
}
.widget.adblock .container {
  display: flex;
  flex-grow: 1;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.widget.adblock .icon-info {
  height: 25px;
  fill: #fff;
  align-self: center;
  margin-right: 20px;
}
.widget.adblock .comment {
  display: flex;
  background-color: #ff4655;
  color: #fff;
  border-radius: 20px;
  padding: 20px;
  margin-bottom: 30px;
  max-width: 80%;
}
.widget.adblock .comment > div {
  display: flex;
}
.widget.adblock .comment .text {
  display: flex;
  flex-direction: column;
}
.widget.adblock .comment .text p:first-child {
  margin-top: 0;
}
.widget.adblock .comment .text p {
  margin-top: 10px;
  font-size: 16px;
}
.widget.adblock .actions {
  display: flex;
  flex-direction: row;
  margin-top: 30px;
}

.widget.adblock .widget.button {
  margin: 0 30px;
}

.widget.adblock .widget.button .sub {
  padding: 15px 25px;
}

.widget.adblock .widget.button:first-child .sub {
  background-color: #94caff;
  color: #544e47;
}

.widget.adblock .widget.button:first-child .sub:hover {
  background-color: #cbecff;
}

@media screen and (max-width: 1024px) {
  .widget.adblock .babies {
    width: 80%;
    height: auto;
  }

  .widget.adblock .actions {
    flex-direction: column;
    margin-top: 20px;
  }
  .widget.adblock .widget.button {
    margin: 10px auto;
  }
}
