.widget.author {
  display: flex;
  text-decoration: none;
  color: #fff;
  position: absolute;
  height: 35px;
  border-radius: 5px;
  background-color: #161326;
  right: 10px;
  top: 10px;
  justify-content: center;
  align-items: center;
  padding: 0 10px;
  box-sizing: border-box;
  opacity: 0.7;
  transition: opacity 0.5s ease;
  outline: 0;
  z-index: 1;
}

.widget.author:hover {
  opacity: 1;
}

.widget.author .created-by {
  color: #8fa3b0;
  font-size: 12px;
  margin-right: 10px;
}

.widget.author img {
  width: 18px;
  height: 18px;
  margin-right: 4px;
}

.widget.author .creator {
  color: #8fa3b0;
  font-size: 15px;
  font-weight: 700;
  max-width: 100px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 20px;
}
