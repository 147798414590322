.widget.media .media-header {
  display: flex;
  flex-direction: row;
  border-width: 0 2px 0 0;
  border-style: solid;
  border-color: #272626;
  padding: 0 10px;
  min-height: 40px;
  align-items: center;
}

.btn a {
  text-decoration: none;
  outline: 0;
}

.btn button {
  position: relative;
  background-color: transparent;
  color: #8fa3b0;
  padding: 5px;
  cursor: pointer;
  border-radius: 3px;
  transition: all 0.5s ease;
  border: 0;
  display: flex;
  align-items: center;
  outline: 0;
  height: auto;
  min-width: 0;
}

.btn .new {
  display: inline-block;
  background-color: #ff4655;
  border-radius: 50%;
  width: 7px;
  height: 7px;
  margin-right: 8px;
}

.btn button svg path {
  transition: fill 0.5s ease;
  fill: #8fa3b0;
}

.btn button span {
  padding-left: 5px;
}

.btn.active button,
.btn button:hover {
  background-color: #262042;
  color: #f9f9f9;
}

.btn.active button svg path,
.btn button:hover svg path {
  fill: #f9f9f9;
}

.widget.media .media-text {
  flex-grow: 1;
}

.widget.media .media-text p {
  color: #ff4655;
  font-size: 18px;
}

.widget.media {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  flex-basis: 0;
}

.widget.media .no-media {
  color: #fff;
  margin: 0 10px;
}

.widget.media .thumbnailview::-webkit-scrollbar,
.widget.media ul::-webkit-scrollbar {
  width: 8px;
}

.widget.media .btn.close {
  display: none;
  font-size: 18px;
}

.widget.media .switch-sort {
  display: flex;
  flex-direction: row;
}

.widget.media .switch-sort .btn:first-child {
  margin-right: 5px;
}

@media screen and (max-width: 1024px) {
  .widget.media ul > li {
    background-color: transparent;
  }

  .widget.media .switch-sort .btn:nth-child(2) {
    margin-right: 10px;
  }

  .widget.media .btn.close {
    display: block;
  }

  .widget.media .btn.close button {
    font-size: 16px;
  }
}
