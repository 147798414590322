.page.agents {
  color: #f9f9f9;
  padding: 50px 20px;
  display: flex;
  flex-direction: column;
  max-width: 800px;
  margin: 0 auto;
}
.page.agents .widget.ad {
  margin-bottom: 20px;
}
.page.agents > div {
  display: flex;
  flex-grow: 1;
  flex-basis: 0;
}
.page.agents .profile {
  display: flex;
  flex-direction: row;
  margin-bottom: 30px;
}

.page.agents .profile > div {
  display: flex;
}
.page.agents .profile > div:first-child {
  display: flex;
  align-items: center;
}

.page.agents img {
  width: 150px;
  height: 150px;
  border-radius: 50%;
  display: block;
  margin: 10px;
}

.page.agents h1 {
  font-size: 24px;
  color: #ff4655;
  display: inline-block;
  margin-bottom: 10px;
  padding-bottom: 4px;
}

.page.agents h3 {
  font-size: 16px;
  font-weight: 400;
}
.page.agents .infos {
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding-right: 20px;
}
.page.agents .infos > div:first-child {
  margin-bottom: 20px;
}
.page.agents .infos > div {
  display: flex;
  flex-direction: column;
}

.page.agents .ab-img.c {
  background-color: #0db397;
}

.page.agents .ab-img.q {
  background-color: #aa98d9;
}

.page.agents .ab-img.e {
  background-color: #e09600;
}

.page.agents .ab-img.x {
  background-color: #c4d210;
}

.page.agents .ab-img {
  width: 35px;
  height: 35px;
  display: flex;
  background-position: center;
  background-size: 20px 20px;
  background-repeat: no-repeat;
  border-radius: 50%;
  margin-right: 20px;
}
.page.agents .avatar-holder {
  display: flex;
  align-items: center;
}
.page.agents .abilities {
  display: flex;
  flex-direction: column;
}

.page.agents .abilities > div {
  display: flex;
  margin-bottom: 15px;
}

.page.agents .abilities h3 {
  color: #ff4655;
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 5px;
}

.page.agents .abilities p {
  font-size: 16px;
  font-weight: 400;
}

.page.agents .abilities .link {
  margin-top: 10px;
}

.page.agents .abilities .link a {
  color: #fff;
  font-size: 16px;
  color: #fff;
}
