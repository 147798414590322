.overwolf {
  display: flex;
  align-items: center;
}

.overwolf button {
  padding: 0;
  border: none;
  cursor: pointer;
}

.overwolf button:focus {
  outline: none;
}

.overwolf .icon {
  position: relative;
  width: var(--size, 30px);
  height: var(--size, 30px);
  transition: 0.15s;
}
.overwolf .icon svg {
  width: 100%;
  height: 100%;
}

.overwolf .window-controls-group {
  margin-left: auto;
  display: flex;
  --color: #a8a8a8;
  --color-hover: #dedede;
  --bg-color-hover: #262042;
}

.overwolf .window-control {
  color: var(--color);
  background-color: transparent;
}

.overwolf .window-control:hover,
.overwolf .window-control:active {
  color: var(--color-hover);
  background-color: var(--bg-color-hover);
}

.overwolf .window-control-close {
  --color-hover: #dedede;
  --bg-color-hover: #c21913;
}

.widget.header {
  flex-basis: 50px;
  display: flex;
  border-bottom: 3px solid #272626;
  color: #f9f9f9;
  padding: 0 10px;
  box-sizing: border-box;
  z-index: 1;
  cursor: move;
  cursor: grab;
}
/*
.widget.header .title {
  align-items: center;
  display: flex;
  font-size: 14px;
  margin-right: 40px;
  color: #ff4655;
  padding-top: 3px;
  cursor: pointer;
  font-family: 'Valorant';
}
.widget.header .title .sl {
  margin-left: 8px;
}
 .widget.header .main-url {
  display: flex;
  text-decoration: none;
  flex-direction: column;
  align-items: center;
  justify-content: center;
} 

.widget.header .title .dot {
  padding: 4px 10px 0px 10px;
  border-radius: 30px;
  background-color: #ff4655;
  color: #fff;
  font-size: 16px;
  box-sizing: border-box;
  margin-top: -6px;
  position: relative;
  overflow: hidden;
  cursor: pointer;
  transform: rotateZ(0deg);
  transition: transform 0.5s ease;
}

.widget.header .title .dot::before {
  position: absolute;
  top: 0;
  left: -75%;
  z-index: 2;
  display: block;
  content: '';
  width: 50%;
  height: 100%;
  background: linear-gradient(
    to right,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0.3) 100%
  );
  -webkit-transform: skewX(-25deg);
  transform: skewX(-25deg);
}
*/
.widget.header:hover .title .dot {
  transform: rotateZ(5deg);
}

/*
.widget.header:hover .title .dot::before {
  -webkit-animation: shine 0.75s;
  animation: shine 0.75s;
}
*/
.widget.header .control-buttons-holder {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.widget.header .discord {
  margin-left: 10px;
}
.widget.header .dual-switch div,
.widget.header .discord a {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  text-decoration: none;
  opacity: 0.7;
  width: 30px;
  height: 30px;
}
.widget.header .dual-switch div .fill,
.widget.header .discord a path {
  fill: #a6a6a6;
}
.widget.header .dual-switch div .stroke {
  stroke: #a6a6a6;
}
.widget.header .dual-switch div:hover .fill,
.widget.header .discord a:hover path {
  fill: #dedede;
}
.widget.header .dual-switch div:hover .stroke {
  stroke: #dedede;
}

.widget.header .dual-switch div:hover,
.widget.header .discord a:hover {
  opacity: 1;
  background-color: #262042;
}
.widget.header .discord img {
  width: 20px;
}
.widget.header .discord p {
  color: #fff;
}
.widget.header .hotkeys {
  display: flex;
  flex-grow: 1;
  justify-content: center;
  padding-right: 20px;
}
.widget.header .hotkeys .hotkey > div {
  display: flex;
  align-items: center;
}
.widget.header .hotkeys .hotkey > div:first-child {
  margin-bottom: 3px;
}
.widget.header .hotkeys .hotkey {
  align-items: center;
  justify-content: center;
  display: flex;
  margin: 0 10px;
  flex-direction: column;
  text-decoration: none;
}
.widget.header .hotkeys p {
  color: #fff;
  font-size: 12px;
}

.widget.header .hotkeys .key {
  font-weight: 600;
}

.widget.header .hotkeys .img-keyboard {
  width: 20px;
  margin-right: 5px;
}

@keyframes shine {
  100% {
    left: 125%;
  }
}

.widget.header nav {
  min-height: 47px;
}

@media screen and (max-width: 1024px) {
  .widget.header {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    flex-basis: auto;
    padding: 10px 10px 0;
    border-bottom: 0px;
  }
  .widget.header .nav {
    width: 100%;
    margin-top: 10px;
  }
  .widget.header .nav > ul {
    flex-direction: column;
    flex-grow: 1;
    margin: 0 -10px;
  }
  .widget.header .title .sl {
    font-size: 13px;
  }
  .widget.header .nav > ul > li {
    height: 40px;
    padding-left: 10px;
    padding-right: 10px;
    flex-direction: row-reverse;
    justify-content: flex-end;
  }
  .widget.header .nav .sublist-holder.open .sublist {
    top: 39px;
    width: 100%;
  }
  .widget.header .nav .sublist-holder p {
    margin-right: 0;
    margin-left: 4px;
  }

  .widget.header .title .dot {
    margin-top: 0;
  }

  .widget.header .download-button-holder {
    display: none;
  }
  .widget.header .control-buttons-holder {
    position: absolute;
    right: 10px;
  }
}
