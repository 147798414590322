.all-container .content {
  display: flex;
  flex-grow: 1;
  flex-basis: 0;
}

.all-container .content .main {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
}

.all-container .content .secondary {
  display: flex;
  flex-basis: 403px;
  flex-direction: column;
  box-sizing: border-box;
  border-width: 0 0 0 3px;
  border-style: solid;
  border-color: #272626;
  flex-grow: 0;
  flex-shrink: 0;
  min-width: 0;
}
.all-container .content .flex {
  display: flex;
  flex-direction: column;
}
.all-container .content .grow {
  display: flex;
  flex-grow: 1;
  flex-basis: 0;
  flex-direction: column;
}

.all-container .content .end {
  justify-content: flex-end;
}

.widget.tags {
  width: 1px;
  height: 1px;
  display: block;
  position: fixed;
  overflow: auto;
  left: -100px;
  top: -100px;
}

body > iframe {
  width: 100% !important;
}

.page.home {
  min-height: 80vh;
}

@media screen and (max-width: 1024px) {
  .page.home {
    flex-direction: column;
    overflow: auto;
  }
  .page.home .widget.play {
    flex-basis: auto;
    flex-direction: unset;
  }
  .page.home .widget.ad {
    margin: 10px auto;
  }

  /* .page.home .secondary {
    flex-basis: auto;
    border-width: 0;
  }
  .page.markdown .flex.grow {
    overflow: hidden;
    flex-direction: column;
  }
  .page.markdown .main {
    flex-direction: unset;
  }
  .widget.markdown {
    padding: 10px;
  }
  .page.markdown .widget.ad {
    margin: 10px auto 0;
  } */
}
@media screen and (max-width: 468px) {
  .page.home .widget.ad {
    margin: 0;
  }
}
@media screen and (min-width: 1550px) {
  .page.home > .main {
    flex-basis: 0;
    flex-grow: 2;
  }
  .page.home > .sidebar {
    flex-basis: 0;
    flex-grow: 1;
  }
}
@media screen and (min-width: 1920px) {
  .page.home > .main {
    flex-basis: 0;
    flex-grow: 4;
    flex-direction: row;
  }
  .page.home > .main > .flex {
    flex-grow: 1;
    flex-basis: 0;
    box-sizing: border-box;
    border-width: 0 3px 0 0;
    border-style: solid;
    border-color: #272626;
  }
  .page.home > .main > .grow {
    flex-grow: 3;
  }
  .page.home > .sidebar {
    flex-basis: 0;
    flex-grow: 1;
  }

  .page.home .widget.filters {
    flex-direction: column;
  }
  .page.home .widget.filters .favs {
    padding-left: 0;
    padding-top: 10px;
  }
  .page.home .widget.filters .favs p {
    margin-top: 2px;
  }
}

/* .all-container .content .hr {
  height: 1px;
  background-color: #8b978f;
  margin-top: 40px;
}

.all-container .content .listing {
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  font-family: 'Nunito', sans-serif;
}

.all-container .content .listing .video-list {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  flex-basis: 33.33%;
  flex-shrink: 1;
  min-width: 0;
}

.all-container .content .66 {
  display: flex;
  flex-grow: 2;
  flex-basis: 66.66%;
  flex-shrink: 1;
  justify-content: center;
  flex-direction: column;
}
 */
