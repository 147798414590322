.widget.button {
  display: flex;
  flex-grow: 1;
  justify-content: flex-end;
  align-items: center;
}
.widget.button a {
  display: flex;
  text-decoration: none;
  color: #fff;
}
.widget.button .sub {
  font-size: 16px;
  background-color: #4f8a7c;
  padding: 5px 10px;
  cursor: pointer;
  border-radius: 5px;
  transition: background-color 0.5s ease;
  position: relative;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-content: center;
}

.widget.button .sub:hover {
  background-color: #63ab9a;
}
.widget.button .sub::before {
  position: absolute;
  top: 0;
  left: -75%;
  z-index: 2;
  display: block;
  content: '';
  width: 50%;
  height: 100%;
  background: linear-gradient(
    to right,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0.3) 100%
  );
  /*
  animation: shine-infinite-delayed 5s infinite;
  */
}

@keyframes shine-infinite-delayed {
  0% {
    left: -75%;
  }
  25% {
    left: 125%;
  }
  100% {
    left: 125%;
  }
}
