.widget.main-logo {
  display: flex;
  text-decoration: none;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.widget.main-logo .title {
  align-items: center;
  display: flex;
  font-size: 14px;
  margin-right: 40px;
  color: #ff4655;
  padding-top: 3px;
  cursor: pointer;
  font-family: 'Valorant';
}
.widget.main-logo .title .sl {
  margin-left: 8px;
}

.widget.main-logo .title .dot {
  padding: 4px 10px 0px 10px;
  border-radius: 30px;
  background-color: #ff4655;
  color: #fff;
  font-size: 16px;
  box-sizing: border-box;
  margin-top: -6px;
  position: relative;
  overflow: hidden;
  cursor: pointer;
  transform: rotateZ(0deg);
  transition: transform 0.5s ease;
}

.widget.main-logo .title .dot::before {
  position: absolute;
  top: 0;
  left: -75%;
  z-index: 2;
  display: block;
  content: '';
  width: 50%;
  height: 100%;
  background: linear-gradient(
    to right,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0.3) 100%
  );
  -webkit-transform: skewX(-25deg);
  transform: skewX(-25deg);
}
