.widget.filterwidget {
  display: flex;
  box-sizing: border-box;
  flex-direction: column;
  color: #fff;
}

.widget.filterwidget .sublist-holder {
  height: 40px;
  border-bottom-width: 1px;
  padding-left: 10px;
  padding-right: 10px;
}

.widget.filterwidget .sublist-holder p {
  margin-left: 8px;
  margin-right: 0;
}

.widget.filterwidget .sublist-holder .sublist {
  top: 40px;
  width: 100%;
  border-width: 2px 0 0 0;
}

.widget.filterwidget .sublist-holder .header-row {
  display: flex;
  flex-direction: row;
  flex-grow: 1;
}

.widget.filterwidget .sublist-holder .header-row > div:first-child {
  flex-grow: 1;
  display: flex;
  align-items: center;
}

.widget.filters {
  flex-direction: row;
  display: flex;
  padding: 10px;
  box-sizing: border-box;
}

.widget.filters .position {
  padding-left: 10px;
}
.widget.filters .position,
.widget.filters .sides {
  display: flex;
  flex-grow: 1;
  flex-basis: 0;
}

.widget.filters .favs {
  display: flex;
  flex-basis: 0;
  flex-direction: row;
  padding-left: 10px;
  padding-top: 6px;
}

.widget.filters .favs p {
  font-size: 10px;
  text-transform: uppercase;
  padding-left: 5px;
  cursor: pointer;
  color: #8fa3b0;
  margin-top: -3px;
}

.widget.filters .favs.checked p {
  color: #fff;
}

.widget.filter-text {
  padding: 10px 0 10px 10px;
}

.widget.filter-text p {
  color: #ff4655;
  font-size: 18px;
}

.widget.filter-text .btn {
  display: none;
}

@media screen and (max-width: 1024px) {
  .widget.filter-text {
    display: flex;
    justify-content: space-between;
    padding: 10px;
  }
  .widget.filter-text .btn {
    display: block;
  }
  .widget.filter-text .btn button {
    font-size: 16px;
  }
}
