.widget.media .thumbnailview {
  overflow: auto;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  flex-basis: 0;
  border-left-width: 0;
  border-right-width: 0;
  border-top-width: 0;
  border-bottom-width: 0;
  border-style: solid;
  border-color: #272626;
  box-sizing: border-box;
  width: 100%;
}

.widget.media .thumbnailview ul {
  list-style-type: none;
  margin: -5px 0 0;
  padding: 0;
  display: flex;
  flex-flow: row wrap;
  display: flex;
}

.widget.media .thumbnailview ul li {
  display: flex;
  flex-grow: 1;
  flex-shrink: 0;
  flex-basis: 100%;
  margin: 0;
  cursor: pointer;
}

.widget.media .thumbnailview li:active {
  transform: scale(0.98);
}

.widget.media .thumbnailview .sc-holder {
  display: flex;
  width: 100%;
  overflow: hidden;
  margin-bottom: 10px;
  position: relative;
}

.widget.media .thumbnailview li:hover img {
  transform: scale(1.2);
}

.widget.media .thumbnailview img {
  display: flex;
  width: 100%;
  border-radius: 5px;
  transition: transform 0.5s ease;
  z-index: 0;
}

.widget.media .thumbnailview .container {
  display: flex;
  padding: 10px;
  margin: 5px;
  flex-direction: column;
  position: relative;
  transition: background-color 0.5s ease;
  border-radius: 5px;
}

.widget.media .thumbnailview li.active .container,
.widget.media .thumbnailview li:hover .container {
  background-color: #262042;
}

.widget.media .thumbnailview h2 {
  font-size: 14px;
  font-weight: 600;
  color: #8fa3b0;
  transition: color 0.5s ease;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
}
.widget.media .thumbnailview li.active h2,
.widget.media .thumbnailview li:hover h2 {
  color: #fff;
}

.widget.media .thumbnailview p {
  font-size: 12px;
  font-weight: 400;
  color: #8fa3b0;
  margin-top: 5px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  line-clamp: 1;
  -webkit-box-orient: vertical;
}

.widget.media .thumbnailview .ab-img.c {
  background-color: #0db397;
}
.widget.media .thumbnailview .ab-img.q {
  background-color: #aa98d9;
}
.widget.media .thumbnailview .ab-img.e {
  background-color: #e09600;
}
.widget.media .thumbnailview .ab-img.x {
  background-color: #c4d210;
}
.widget.media .thumbnailview .ab-img.setups {
  background-color: #ff4655;
}
.widget.media .thumbnailview .ab-img {
  width: 30px;
  height: 30px;
  display: flex;
  background-position: center;
  background-size: 20px 20px;
  background-repeat: no-repeat;
  border-radius: 50%;
  position: absolute;
  top: 5px;
  right: 5px;
  z-index: 1;
  animation-duration: 0.5s;
  animation-iteration-count: 1;
  animation-timing-function: ease;
}

.widget.media .thumbnailview li:hover .ab-img {
  animation-name: rotation;
}

@keyframes rotation {
  0% {
    transform: rotate(-360deg);
  }
  100% {
    transform: rotate(0deg);
  }
}

.widget.media .thumbnailview .new-media {
  display: block;
  position: absolute;
  top: 5px;
  left: 5px;
  z-index: 1;
  background-color: #ff4655;
  font-size: 8px;
  border-radius: 10px;
  color: #fff;
  padding: 3px;
  font-weight: 600;
}

.widget.media .thumbnailview .is-fav {
  display: flex;
  position: absolute;
}

.widget.media .thumbnailview footer {
  display: flex;
  flex-direction: row;
}

.widget.media .thumbnailview footer > div {
  display: flex;
}

.widget.media .thumbnailview footer > div:nth-child(2) {
  flex-grow: 1;
  flex-basis: 0;
  flex-direction: column;
}

.widget.media .thumbnailview footer > div:nth-child(1) {
  flex-basis: 30px;
  align-items: center;
}

.widget.media .thumbnailview .locked-content {
  display: none;
}

.widget.media .thumbnailview .locked-content.locked {
  display: flex;
  position: absolute;
  flex-grow: 1;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  z-index: 1;
}

.widget.media .thumbnailview .locked-content.locked ~ img {
  filter: blur(3px);
}

.widget.media .thumbnailview .locked-content.locked > div {
  display: flex;
}

.widget.media .thumbnailview .locked-content.locked .img-holder {
  display: flex;
  z-index: 1;
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 3px;
  padding: 5px 10px;
  align-items: center;
}

.widget.media .thumbnailview .locked-content.locked svg {
  width: 15px;
  height: 15px;
  fill: #8fa3b0;
}

.widget.media .thumbnailview .locked-content.locked .sub-only {
  margin-left: 10px;
  font-size: 14px;
  text-shadow: 0px 0px 3px #000;
  margin-top: -2px;
}

@media screen and (max-width: 639px) {
  .widget.media .thumbnailview ul li {
    flex-basis: 100%;
  }
}

@media screen and (max-width: 1024px) and (min-width: 640px) {
  .widget.media .thumbnailview ul li {
    flex-basis: 50%;
  }
}

@media screen and (min-width: 1550px) {
  .widget.media .thumbnailview ul li {
    flex-basis: 50%;
  }
}
