.widget.ad {
  display: block;
  position: relative;
  border-top: 3px solid #292d31;
  margin: auto;
}

.widget.ad > iframe {
  display: block;
  margin: auto;
}
.widget.ad.borderless {
  border: 0;
}
