.widget.markdown {
  color: #f9f9f9;
  font-weight: 400;
  display: block;
  padding: 30px;
  max-width: 800px;
  margin: 0 auto;
}
.widget.markdown em {
  font-style: italic;
  font-size: 16px;
}
.widget.markdown strong {
  font-weight: 700;
}
.widget.markdown table {
  margin-bottom: 10px;
}
.widget.markdown ul {
  margin: 0 0 1rem;
}
.widget.markdown ul li {
  margin: 0 0 0.2rem;
  font-size: 16px;
}

.widget.markdown a {
  color: #fff;
}
.widget.markdown h1,
.widget.markdown h2,
.widget.markdown h3,
.widget.markdown h4,
.widget.markdown h5,
.widget.markdown h6,
.widget.markdown p {
  width: fit-content;
  margin-bottom: 1rem;
}
.widget.markdown h1 {
  margin-bottom: 1.5rem;
}
.widget.markdown h1 {
  color: #fff;
  font-size: 32px;
}

.widget.markdown h2 {
  padding-bottom: 4px;
  font-size: 24px;
  color: #fff;
  border-bottom: 2px solid #ff4655;
}

.widget.markdown h3 {
  font-size: 18px;
}

.widget.markdown h4 {
  font-size: 16px;
}

.widget.markdown h5 {
  font-size: 13px;
  font-weight: 300;
}

.widget.markdown h6 {
  font-size: 10px;
  font-weight: 300;
}

.widget.markdown p {
  font-size: 16px;
  font-weight: 300;
}

.widget.markdown .ad-container {
  display: flex;
  flex-grow: 1;
}

.page.markdown .flex.grow {
  overflow: auto;
}

.page.markdown .widget.ad {
  margin: 30px auto 0;
}

.page.markdown .widget.ad {
  margin-bottom: 20px;
}

@media screen and (max-width: 1024px) {
  .widget.markdown {
    padding: 10px;
  }
  .page.markdown .widget.ad {
    margin: 10px auto 0;
  }
}
