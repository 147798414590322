.widget.play {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  flex-basis: 0;
  min-height: 0;
  position: relative;
}

.widget.play .subscribe {
  z-index: 1;
  flex-grow: 1;
  position: absolute;
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
}
.widget.play .subscribe .sub-line {
  color: #fff;
  text-align: center;
  background-color: rgba(0, 0, 0, 0.5);
  padding: 20px;
  border-radius: 5px;
}

.widget.play .subscribe .sub-line p {
  margin: 5px 0 30px;
  font-size: 16px;
}

.widget.play .subscribe .sub-line svg {
  fill: #fff;
  width: 50px;
}

.widget.play .play-content.locked {
  overflow: hidden;
}

.widget.play .play-content.locked .widget.author {
  display: none;
}

.widget.play .play-content.locked video {
  filter: blur(30px);
}

.widget.play .play-content {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  flex-basis: 0;
  min-height: 0;
  position: relative;
}

.widget.play video {
  width: 100%;
  height: 100%;
  object-fit: cover;
  outline: 0;
}
