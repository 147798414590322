.widget.nomedia {
  display: flex;
  flex-grow: 1;
  flex-basis: 0;
  min-height: 0;
  position: relative;
  justify-content: center;
}

.widget.nomedia > div {
  display: flex;
  align-items: center;
  justify-content: center;
}

.widget.nomedia .agent {
  height: 80%;
}

.widget.nomedia .icon-info {
  height: 25px;
  fill: #fff;
  align-self: center;
  margin-right: 15px;
}

.widget.nomedia .comment {
  display: flex;
  align-self: flex-start;
  background-color: #ff4655;
  color: #fff;
  border-radius: 20px 20px 20px 0;
  max-width: 250px;
  padding: 15px;
  font-size: 16px;
  margin-top: 10%;
  margin-left: -5%;
}

.widget.nomedia .comment > div {
  display: flex;
}

@media screen and (max-width: 1024px) {
  .widget.nomedia {
    flex-grow: unset;
    flex-basis: unset;
    margin: 30px 0;
  }
  .widget.nomedia > div {
    flex-direction: column-reverse;
  }
  .widget.nomedia .comment {
    margin: 0 auto 20px;
  }
  .widget.nomedia .agent {
    width: 70%;
    height: auto;
  }
}
