.widget.footer {
  color: #f9f9f9;
  padding: 40px 20px;
}
.widget.footer a {
  color: #f9f9f9;
}

.widget.footer > div {
  display: flex;
  flex-direction: column;
}

.widget.footer .contact {
  margin: 20px 0 0;
  align-items: center;
}

.widget.footer .lists {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.widget.footer .lists > div {
  display: flex;
  flex-grow: 1;
  justify-content: center;
}

.widget.footer ul {
  list-style-type: none;
}

.widget.footer ul li {
  font-size: 14px;
  padding: 2px 0;
}

.widget.footer ul.agent-links li {
  font-size: 12px;
}

.widget.footer ul li a {
  text-decoration: none;
  color: #8fa3b0;
}

.widget.footer .widget.download-btn {
  justify-content: center;
  margin: 20px 0 0;
}
