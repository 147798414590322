.widget.media ul.listview.listview {
  overflow: auto;
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  flex-basis: 0;
  border-left-width: 0;
  border-right-width: 0;
  border-top-width: 0;
  border-bottom-width: 0;
  border-style: solid;
  border-color: #272626;
  box-sizing: border-box;
  width: 100%;
}

.widget.media ul.listview > li .is-fav {
  padding: 0 10px;
  display: flex;
}

.widget.media ul.listview > li {
  box-sizing: border-box;
  display: flex;
  flex-basis: 0;
  background-color: #161326;
  cursor: pointer;
  position: relative;
  border-color: #292d31;
  border-style: solid;
  border-width: 0 0 1px 0;
  align-items: center;
  flex-shrink: 1;
  min-height: 60px;
}

.widget.media ul.listview > li > .li-content {
  display: flex;
  flex-grow: 1;
  align-items: center;
  flex-shrink: 1;
  min-width: 0;
}

.widget.media ul.listview > li > .li-content.locked {
  position: relative;
  filter: blur(3px);
}

.widget.media ul.listview > li .locked-content {
  display: none;
}

.widget.media ul.listview > li .locked-content.locked {
  display: flex;
  position: absolute;
  flex-grow: 1;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  z-index: 1;
}

.widget.media ul.listview > li .locked-content.locked > div {
  display: flex;
}

.widget.media ul.listview > li .locked-content.locked .img-holder {
  display: flex;
  z-index: 1;
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 3px;
  padding: 5px 10px;
  align-items: center;
}

.widget.media ul.listview > li .locked-content.locked svg {
  width: 15px;
  height: 15px;
  fill: #8fa3b0;
}

.widget.media ul.listview > li .locked-content.locked .sub-only {
  margin-left: 10px;
  font-size: 14px;
  text-shadow: 0px 0px 3px #000;
  margin-top: -2px;
}

.widget.media ul.listview > li:hover .locked-content.locked svg,
.widget.media ul.listview > li.active .locked-content.locked svg {
  fill: #fff;
}

.widget.media ul.listview > li:hover .locked-content.locked .sub-only,
.widget.media ul.listview > li.active .locked-content.locked .sub-only {
  color: #fff;
}

.widget.media ul.listview > li .triangle {
  display: block;
  position: absolute;
  top: 0;
  right: 0;
}

.widget.media ul.listview > li .new-media {
  display: inline-block;
  background-color: #ff4655;
  font-size: 8px;
  border-radius: 10px;
  color: #fff;
  padding: 3px;
  font-weight: 600;
  position: absolute;
  right: -10px;
  top: -2px;
}

.widget.media ul.listview > li.active,
.widget.media ul.listview > li:hover {
  background-color: #262042;
}

.widget.media ul.listview > li:before {
  content: '';
  width: 4px;
  transition: all 0.25s;
  height: 100%;
}

.widget.media ul.listview > li:hover:before {
  background-color: #292d31;
}

.widget.media ul.listview > li.active:before,
.all-container
  .content
  .listing
  .video-list
  .videos
  ul
  > li.active:hover:before {
  background-color: #ff4655;
}
.widget.media ul.listview > li .ability {
  display: flex;
  flex-basis: 0;
  padding-left: 10px;
  position: relative;
}

.widget.media ul.listview > li .text {
  display: flex;
  flex-direction: column;
  padding-left: 20px;
  padding-right: 10px;
  flex-grow: 1;
  flex-basis: 0;
  min-width: 0;
}
.widget.media ul.listview > li .ab-img.c {
  background-color: #0db397;
}
.widget.media ul.listview > li .ab-img.q {
  background-color: #aa98d9;
}
.widget.media ul.listview > li .ab-img.e {
  background-color: #e09600;
}
.widget.media ul.listview > li .ab-img.x {
  background-color: #c4d210;
}
.widget.media ul.listview > li .ab-img.setups {
  background-color: #ff4655;
}
.widget.media ul.listview > li .ab-img {
  width: 35px;
  height: 35px;
  display: flex;
  background-position: center;
  background-size: 20px 20px;
  background-repeat: no-repeat;
  border-radius: 50%;
}

.widget.media ul.listview > li.active h3,
.widget.media ul.listview > li:hover h3 {
  color: #fff;
}

.widget.media ul.listview > li p,
.widget.media ul.listview > li h3 {
  color: #8fa3b0;
}

.widget.media ul.listview > li h3 {
  font-size: 16px;
  font-weight: 400;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: inline-block;
  overflow: hidden;
}

.widget.media ul.listview > li p {
  padding-top: 4px;
  font-size: 12px;
  font-weight: 700;
}
