@font-face {
  font-family: 'Valorant';
  src: local('Valorant'), url('./Fonts/valorant.ttf') format('woff');
}

@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,300;0,400;0,700;1,300;1,400;1,700&display=swap');

@keyframes pulse-offline {
  0% {
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.9);
  }
  70% {
    box-shadow: 0 0 0 5px rgba(255, 255, 255, 0);
  }
  100% {
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
  }
}

@keyframes pulse-online {
  0% {
    box-shadow: 0 0 0 0 rgba(33, 68, 44, 0.9);
  }
  70% {
    box-shadow: 0 0 0 5px rgba(33, 68, 44, 0);
  }
  100% {
    box-shadow: 0 0 0 0 rgba(33, 68, 44, 0);
  }
}

#root {
  display: flex;
  flex-direction: column;
}

.overflow {
  overflow: auto;
}

body {
  user-select: none;
  background-color: #ece8e1;
  font-family: 'Lato', sans-serif;
  background-color: #161326;
  color: #101823;
  font-weight: 400;
  font-size: 14px;
  background-size: 400px;
  margin: 0;
}
p,
h1,
h2,
h3 {
  margin: 0;
  line-height: 1;
}

h1 {
  font-size: 32px;
}
h2 {
  font-size: 28px;
}
h3 {
  font-size: 24px;
}
p {
  font-size: 14px;
}

input {
  height: 40px;
  box-sizing: border-box;
  border: 1px solid #292d31;
  background-color: #161326;
  border-radius: 5px;
  min-width: 250px;
  color: #f9f9f9;
  padding: 5px 10px;
}

button {
  height: 40px;
  box-sizing: border-box;
  border: 1px solid #292d31;
  background-color: #161326;
  border-radius: 5px;
  min-width: 250px;
  color: #f9f9f9;
  padding: 5px 10px;
  cursor: pointer;
  transition: background-color 0.5s ease;
}

button:hover {
  background-color: #262042;
}

button:active {
  transition: none;
  background-color: #b63542;
}

input:focus-visible,
button:focus-visible {
  outline-color: #aa99d8;
  outline-width: 1px;
  outline-style: solid;
}

.widget-container {
  display: flex;
}

.app {
  display: flex;
  width: 100%;
  background-color: #161326;
}

.all-container {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.nav {
  display: flex;
}

.nav > ul {
  display: flex;
  list-style-type: none;
  padding: 0;
  margin: 0;
  margin-bottom: -2px;
}

.nav .sublist-holder {
  display: flex;
  color: #f9f9f9;
  padding-left: 24px;
  padding-right: 24px;
  flex-grow: 1;
  align-items: center;
  cursor: pointer;
  border: 0;
  border-bottom-width: 2px;
  border-style: solid;
  border-color: #272626;
  transition: border-color 0.5s ease;
  position: relative;
  height: 100%;
  box-sizing: border-box;
}

.nav .sublist-holder.open,
.nav .sublist-holder:hover {
  background-color: #262042;
}

.nav .sublist-holder p {
  margin-right: 4px;
}

.nav .sublist-holder.open {
  border-color: #ff4655;
}

.nav .sublist-holder.open .sublist {
  opacity: 1;
  visibility: visible;
  z-index: 2;
}

.nav .sublist-holder.open .nav-arrow path {
  fill: #fff;
}

.nav .sublist {
  position: absolute;
  z-index: 1;
  top: 50px;
  left: 0;
  display: block;
  list-style-type: none;
  padding: 0;
  margin: 0;
  flex-direction: column;
  min-width: 200px;
  background-color: #111;
  visibility: hidden;
  opacity: 0;
  transition: opacity 0.5s ease;
  max-height: 400px;
  overflow-y: auto;
  background-color: #161326;
  border-width: 0 1px 1px 1px;
  border-color: #272626;
  box-sizing: border-box;
  border-style: solid;
}

.nav .sublist::-webkit-scrollbar {
  width: 8px;
}

.nav .sublist > li {
  padding: 12px 24px;
  transition: background-color 0.5s ease;
  overflow: hidden;
  cursor: pointer;
  transition-duration: 0.4s;
  position: relative;
  font-size: 13px;
}

.nav .sublist > li.active {
  text-decoration: underline;
}

.nav .sublist > li:hover {
  background-color: #262042;
}
.nav .sublist.linked > li {
  padding: 0;
}
.nav .sublist.linked > li.padded {
  padding: 12px 24px;
}
.nav .sublist.linked > li > a {
  display: flex;
  padding: 12px 24px;
  text-decoration: none;
  color: #f9f9f9;
}

::-webkit-scrollbar {
  width: 15px;
}

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 3px #333;
}

::-webkit-scrollbar-thumb {
  background: #333;
  border-radius: 3px;
}

::-webkit-scrollbar-thumb:hover {
  background: #ff4655;
}

.toggle {
  display: flex;
  position: relative;
  flex-direction: row;
  border: 1px solid #292d31;
  height: 30px;
  margin: 0;
  padding: 0;
  border-radius: 5px;
  flex-grow: 1;
}

.toggle li {
  display: flex;
  flex-grow: 1;
  flex-basis: 33.33%;
  transition: background-color 0.5s ease;
  background-color: #161326;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  position: relative;
  overflow: hidden;
}

.toggle li:first-child {
  border-radius: 5px 0 0 5px;
}

.toggle li:last-child {
  border-radius: 0 5px 5px 0;
}

.toggle li:hover {
  background-color: #262042;
}

.toggle li.active {
  background-color: #b63542;
}

.toggle li.active p {
  text-decoration: underline;
}

.toggle li input {
  display: none;
}

.toggle li p {
  cursor: pointer;
  color: #fff;
}

.toggle li:after {
  content: '';
  background-color: rgba(255, 70, 85, 0.5);
  display: block;
  position: absolute;
  opacity: 0;
  transition: all 0.8s;
  padding-top: 300%;
  padding-left: 350%;
  margin-left: -20px !important;
  margin-top: -120%;
  z-index: 0;
}

.toggle li:not(.active):active:after {
  padding: 0;
  margin: 0;
  opacity: 1;
  transition: 0s;
}
.toggle li label {
  z-index: 1;
}
.checkbox {
  cursor: pointer;
  display: block;
  width: 15px;
  height: 15px;
}

.checkbox input {
  display: none;
}
.grabbable {
  -webkit-app-region: drag;
  cursor: grab;
}

.not-grabbable {
  -webkit-app-region: no-drag;
}

.grabbable:active {
  cursor: grabbing;
}

@media screen and (max-width: 1024px) {
  #root {
    min-height: auto;
    min-width: auto;
  }
  .all-container .content .sidebar {
    left: -100%;
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    border: 0;
    z-index: 10;
    background-color: rgba(22, 19, 38, 0.9);
    min-width: 406px;
    transition: left 0.5s ease;
  }

  .all-container .content .widget-media-holder {
    width: 70%;
    background-color: #161326;
  }

  .all-container .content .sidebar::before {
    content: 'Close';
    display: flex;
    align-items: flex-end;
    position: absolute;
    right: 20px;
    bottom: 20px;
    color: #8fa3b0;
    font-size: 20px;
  }

  .all-container .content .sidebar.open {
    left: 0;
  }
}

@media screen and (max-width: 406px) {
  .all-container .content .sidebar {
    min-width: 0;
  }

  .all-container .content .widget-media-holder {
    width: 100%;
  }

  .all-container .content .sidebar::before {
    display: none;
  }
}

/* enable this for test .adsbygoogle {
  background-color: #262042;
} */
