.widget.videoloading {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  flex-basis: 0;
  min-height: 0;
  justify-content: center;
  align-items: center;
}

.widget.videoloading p {
  color: #fff;
  font-size: 16px;
  margin-bottom: 20px;
  line-height: 1.3;
}
