.widget.loading {
  fill: none;
  stroke-width: 8px;
  stroke: #ff4655;
  stroke-dasharray: 40;
  stroke-dashoffset: 50%;
  animation: movedash 1.5s forwards 0s infinite;
}

@keyframes movedash {
  to {
    stroke-dashoffset: 250%;
  }
}
