.widget.agentinfo {
  color: #f9f9f9;
  padding: 50px 20px;
  display: flex;
  flex-direction: row;
}

.widget.agentinfo > div {
  display: flex;
  flex-grow: 1;
  flex-basis: 0;
}
.widget.agentinfo .profile {
  display: flex;
  flex-direction: row;
}

.widget.agentinfo .profile > div {
  display: flex;
}
.widget.agentinfo .profile > div:first-child {
  display: flex;
  align-items: center;
}

.widget.agentinfo img {
  width: 150px;
  height: 150px;
  border-radius: 50%;
  display: block;
  margin: 10px;
}

.widget.agentinfo h1 {
  font-size: 24px;
  color: #ff4655;
  display: inline-block;
  margin-bottom: 10px;
  padding-bottom: 4px;
}

.widget.agentinfo h3 {
  font-size: 14px;
  font-weight: 400;
}
.widget.agentinfo .infos {
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding-right: 20px;
}
.widget.agentinfo .infos > div:first-child {
  margin-bottom: 20px;
}
.widget.agentinfo .infos > div {
  display: flex;
  flex-direction: column;
}

.widget.agentinfo .ab-img.c {
  background-color: #0db397;
}

.widget.agentinfo .ab-img.q {
  background-color: #aa98d9;
}

.widget.agentinfo .ab-img.e {
  background-color: #e09600;
}

.widget.agentinfo .ab-img.x {
  background-color: #c4d210;
}

.widget.agentinfo .ab-img {
  width: 35px;
  height: 35px;
  display: flex;
  background-position: center;
  background-size: 20px 20px;
  background-repeat: no-repeat;
  border-radius: 50%;
  margin-right: 20px;
}
.widget.agentinfo .avatar-holder {
  display: flex;
  align-items: center;
}
.widget.agentinfo .abilities {
  display: flex;
  flex-direction: column;
}

.widget.agentinfo .abilities > div {
  display: flex;
  margin-bottom: 15px;
}

.widget.agentinfo .abilities h3 {
  color: #ff4655;
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 5px;
}

.widget.agentinfo .abilities p {
  font-weight: 400;
}

.widget.agentinfo .abilities .link {
  margin-top: 10px;
}

.widget.agentinfo .abilities .link a {
  color: #fff;
  font-size: 16px;
  color: #fff;
}

@media screen and (max-width: 1024px) {
  .widget.agentinfo {
    flex-direction: column;
  }

  .widget.agentinfo .profile {
    margin-bottom: 30px;
  }

  .widget.agentinfo .infos {
    padding-right: 0;
  }
}
